import React from "react"
import { useSelector } from "react-redux"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  const { settings } = useSelector(state => ({
    settings: state.Settings.settings,
  }))

  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>
              {new Date().getFullYear()} © {settings?.company?.name}
            </Col>
            <Col md={6}>
              {/* <div className="text-sm-end d-none d-sm-block">
                from{" "}
                <a target="blank" href="https://osperb.com">
                  osperb
                </a>
              </div> */}
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
