import { debounce, isEmpty } from "lodash"
import PropTypes from "prop-types"
import classnames from "classnames"
import { useHistory } from "react-router"
import React, { useEffect, useState } from "react"
import { Col, Container, FormGroup, Label } from "reactstrap"
import { AvInput } from "availity-reactstrap-validation"
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation"
import { Card, CardBody, CardText, CardTitle } from "reactstrap"
import { Row, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import Select from "react-select"
import { map } from "lodash"
import { WithContext as ReactTags } from "react-tag-input"
import { BsEnvelopePaperFill, BsFillBuildingsFill } from "react-icons/bs"
import { TbReceiptTax } from "react-icons/tb"
import { GiSatelliteCommunication } from "react-icons/gi"
import { GrLanguage } from "react-icons/gr"
import { MdOutlinePayment, MdPhonelinkSetup } from "react-icons/md"

//actions
import { getSettings, getStores, updateSettings } from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import FormEditors from "./Editor"

import Switch from "react-switch"
import { ChromePicker } from "react-color"
import rgbHex from "rgb-hex"
import { BiMessageSquareDetail } from "react-icons/bi"

const Settings = props => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { settings, loading, stores, StoreLoading } = useSelector(state => ({
    settings: state.Settings.settings,
    loading: state.Banner.loading,
    stores: state.Stores.stores?.stores,
    StoreLoading: state.Stores.loading,
  }))

  const [customIconActiveTab, setcustomIconActiveTab] = useState("1")

  const [storeSearchText, setStoreSearchText] = useState("")
  const [selectedStore, setSelectedStore] = useState("")
  const [finalStoreId, setFinalStoreId] = useState()
  const [siteVersion, setSiteVersion] = useState("basic")
  const [primaryColor, setPrimaryColor] = useState("")
  const [secondaryColor, setSecondaryColor] = useState("")

  const [creditionalsChange, setCreditionalsChange] = useState("RazorPay")

  const paymentTypes = ["Cash on Delivery", "Online Payment", "Both"]
  const paymentGateways = ["RazorPay", "Open Payments", "PhonePe"]

  const deliveryOptions = ["Same Day", "Next Day", "Within Duration"]

  const shippingInputData = [
    "name",
    "firstName",
    "middleName",
    "lastName",
    "email",
    "contactNumber",
    "whatsappNumber",
    "address",
    "addressLine1",
    "addressLine2",
    "street",
    "landmark",
    "area",
    "city",
    "district",
    "state",
    "country",
    "otherCountry",
    "pinCode",
  ]

  const productInputData = [
    //
    "name",
    "image",
    "category",
    "sellingPrice",
    "stockValue",
    "status",
    //
    "itemCode",
    "barcode",
    "fittingData",
    "packageData",
    "shippingData",
    "labels",
    "specs",
    "keywords",
    "secondaryLang",
    "tax",
    "discount",
    "profit",
  ]

  // const classificationsInputData = [
  //   "category",
  //   "subcategory",
  //   "gendertype",
  //   "agegroup",
  //   "brand",
  // ]

  const requiredOrNot = ["Required", "Not Required"]

  const languages = ["english", "arabic"]

  const toggleIconCustom = tab => {
    if (customIconActiveTab !== tab) {
      setcustomIconActiveTab(tab)
    }
  }

  useEffect(() => {
    dispatch(getStores(1, storeSearchText))
  }, [dispatch, storeSearchText])

  useEffect(() => {
    dispatch(getSettings())
  }, [dispatch])

  useEffect(() => {
    setTaxCategories(
      settings?.taxDetails?.taxCategories || [{ name: "", percentage: 0 }]
    )
    setShippingInputs(
      settings?.setup?.shippingInputs || [{ name: "", required: "" }]
    )
    setProductInputs(
      settings?.setup?.productInputs || [{ name: "", required: "" }]
    )
    setClassificationInputs(
      settings?.setup?.classificationInputs || [{ name: "", required: "" }]
    )
    setImages(settings?.company?.logo)
    setOldImages(settings?.company?.logo)

    setPrivacyPolicy(settings?.legal?.privacyPolicy || "")
    setTerms(settings?.legal?.terms || "")
    setReturnPolicy(settings?.legal?.returnPolicy || "")
    setMultilanguage(settings?.multiLanguange?.isMultiLanguage || false)
    setWhatsappApiConnected(
      settings?.communications?.isWhatsappApiConnected || false
    )

    setFinalStoreId(settings?.setup?.defaultStore)

    setSiteVersion(settings?.setup?.version)
  }, [settings, loading])

  const [privacyPolicy, setPrivacyPolicy] = useState(
    settings?.legal?.privacy || ""
  )
  const [terms, setTerms] = useState(settings?.legal?.terms || "")
  const [returnPolicy, setReturnPolicy] = useState(
    settings?.legal?.returnPolicy || ""
  )
  const [images, setImages] = useState(settings?.company?.logo)
  const [oldImages, setOldImages] = useState(settings?.company?.logo)
  const [imagesPreview, setImagesPreview] = useState([])
  const [taxCategories, setTaxCategories] = useState(
    settings?.taxDetails?.taxCategories || [{ name: "", percentage: 0 }]
  )
  const [shippingInputs, setShippingInputs] = useState(
    settings?.setup?.shippingInputs || [""]
  )
  const [productInputs, setProductInputs] = useState(
    settings?.setup?.productInputs || [""]
  )
  const [classificationInputs, setClassificationInputs] = useState(
    settings?.setup?.classificationInputs || [""]
  )
  const [multilanguage, setMultilanguage] = useState(
    settings?.multiLanguange?.isMultiLanguage || false
  )
  const [whatsappApiConnected, setWhatsappApiConnected] = useState(
    settings?.communications?.isWhatsappApiConnected || false
  )

  const onChangeImageHandler = e => {
    const files = Array.from(e.target.files)

    setImagesPreview([])
    setImages([])
    setOldImages([])

    files.forEach(file => {
      const reader = new FileReader()

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImagesPreview(oldArray => [...oldArray, reader.result])
          setImages(oldArray => [...oldArray, reader.result])
        }
      }

      reader.readAsDataURL(file)
    })
  }

  const rgbtohex = c => {
    if (c?.r) {
      return "#" + rgbHex(c.r, c.g, c.b, c.a)
    }
  }

  const KeyCodes = {
    comma: 188,
    enter: 13,
  }
  const delimiters = [KeyCodes.comma, KeyCodes.enter]
  const [isShiprocketConnected, setIsShiprocketConnected] = useState()
  const [pickUpLocation, setPickUpLocation] = useState([])
  const [tags, setTags] = useState([])
  // const suggestions = categoryList.map(category => {
  //   return {
  //     id: category._id,
  //     text: category.primaryLang.name,
  //   }
  // })

  const handleDeleteTag = i => {
    setTags(tags.filter((tag, index) => index !== i))
    const arrangedKeywords = tags.map(a => a.text)
    setPickUpLocation(arrangedKeywords)
  }

  const handleAddition = tag => {
    setTags([...tags, tag])
    const arrangedKeywords = [...tags, tag].map(a => a.text)
    setPickUpLocation(arrangedKeywords)
  }

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice()
    newTags.splice(currPos, 1)
    newTags.splice(newPos, 0, tag)
    // re-render
    setTags(newTags)
    const arrangedKeywords = newTags.map(a => a.text)
    setPickUpLocation(arrangedKeywords)
  }

  const handleTagClick = index => {
    console.log("The tag at index " + index + " was clicked")
  }

  useEffect(() => {
    const pickUpLocation = settings?.logistics?.pickupLocations
    const modifiedList = []
    pickUpLocation?.map(i => modifiedList?.push({ id: i, text: i }))

    setTags(modifiedList)
    setPickUpLocation(pickUpLocation)
    setIsShiprocketConnected(settings?.logistics?.isShiprocketConnected)
  }, [settings?.logistics])

  function handleValidSubmit(values) {
    const updateSettingsData = {
      company: {
        name: values.name,
        address: values.address,
        location: [0, 0],
        contactDetails: values.contactDetails,
        socialLinks: values.socialLinks,
        logo: images,
        defaultCurrency: values.defaultCurrency,
        defaultCurrencySymbol: values.defaultCurrencySymbol,
      },
      taxDetails: {
        taxId: values.taxId,
        taxPhrase: values.taxPhrase,
        defaultTaxPercentage: values.defaultTaxPercentage,
        taxCategories: taxCategories,
      },
      payment: {
        paymentType: values.paymentType,
        paymentGateway: values.paymentGateway,
        razorPayCreditionals: {
          encryptedKeyId: values.encryptedKeyId,
          encryptedKeySecret: values.encryptedKeySecret,
        },
        openPaymentCreditionals: {
          encryptedApiKey: values?.encryptedApiKey,
          encryptedApiSecret: values?.encryptedApiSecret,
        },
        phonePeCreditionals: {
          merchantId: values?.merchantId,
          phonePeKey: values?.phonePeKey,
        },
      },
      shipping: {
        defaultShippingCharge: values.defaultShippingCharge,
        returnDuration: values.returnDuration,
        freeShippingThreshold: values.freeShippingThreshold,
        deliveryOption: values.deliveryOption,
        sameDayTimeLimit: values.sameDayTimeLimit,
        deliveryDuration: values.deliveryDuration,
        isTrackingAvailable: values.isTrackingAvailable,
        trackingBaseUrl: values.trackingBaseUrl,
        codCharge: values.codCharge,
      },
      legal: {
        establishmentName: values.establishmentName,
        privacyPolicy: privacyPolicy,
        returnPolicy: returnPolicy,
        terms: terms,
      },
      setup: {
        shippingInputs,
        productInputs,
        classificationInputs,
        orderIdTag: values.orderIdTag,
        adminEmail: values.adminEmail,
        frontendurl: values.frontendUrl,
        defaultStore: finalStoreId,
        version: siteVersion,
        storeSearchDistance: settings.setup.storeSearchDistance,
        theme: {
          primaryColor: primaryColor,
          secondaryColor: secondaryColor,
        },
        isCustomHomepage: values?.isCustomHomepage,
      },
      logistics: {
        isShiprocketConnected: values?.isShiprocketConnected,
        shiprocketToken: values?.shiprocketToken,
        shiprocketBaseUrl: values?.shiprocketBaseUrl,
        shiprocketApiEmail: values?.shiprocketApiEmail,
        shiprocketApiPassword: values?.shiprocketApiPassword,
        pickupLocations: pickUpLocation,
        defaultPackageDimensions: {
          length: parseFloat(values?.length),
          breadth: parseFloat(values?.breadth),
          height: parseFloat(values?.height),
          weight: parseFloat(values?.weight),
        },
      },

      communications: {
        isWhatsappApiConnected: whatsappApiConnected,
        whatsappApiBaseUrl: values?.whatsappApiBaseUrl,
        whatsappApiToken: values?.whatsappApiToken,
      },

      specialMessages: values.specialMessages,

      multiLanguange: {
        secondaryLanguageName: values.secondaryLanguageName,
        isMultiLanguage: multilanguage,
        priority: values.priority,
      },

      // shopSearchDistance: parseFloat(values.shopSearchDistance),
    }
    dispatch(updateSettings(updateSettingsData, settings?._id, history))
  }

  useEffect(() => {
    setPrimaryColor(settings?.setup?.theme?.primaryColor)
    setSecondaryColor(settings?.setup?.theme?.secondaryColor)
  }, [settings?.setup])

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    )
  }

  //store select
  const debounceStoreSearch = debounce(value => setStoreSearchText(value), 600)
  function handleEnters(textEntered) {
    debounceStoreSearch(textEntered)
  }

  function handlerProductFinalValue(event) {
    setSelectedStore(event.label)
    setFinalStoreId(event.id)
  }

  const storeOptions = [
    {
      options: stores?.map((result, index) => ({
        key: index,
        label: result?.storeName,
        id: result?._id,
      })),
    },
  ]

  useEffect(() => {
    const filteredStore = stores?.filter(
      i => i?._id === settings?.setup?.defaultStore
    )
    if (filteredStore) {
      setSelectedStore(filteredStore[0]?.storeName)
    }
  }, [stores, settings])

  useEffect(() => {
    setCreditionalsChange(settings?.payment?.paymentGateway)
  }, [settings])

  const settingsHeadItems = [
    {
      tab: "1",
      icon: <BsFillBuildingsFill />,
      title: "Company",
    },
    {
      tab: "2",
      icon: <TbReceiptTax />,
      title: "Tax Details",
    },
    {
      tab: "3",
      icon: <MdOutlinePayment />,
      title: "Payment",
    },
    {
      tab: "4",
      icon: <i className="fas fa-dolly"></i>,
      title: "Shipping",
    },
    {
      tab: "5",
      icon: <BsEnvelopePaperFill />,
      title: "Legal",
    },
    {
      tab: "6",
      icon: <BiMessageSquareDetail />,
      title: "Messages",
    },
    {
      tab: "7",
      icon: <MdPhonelinkSetup />,
      title: "Setup",
    },
    {
      tab: "9",
      icon: <GiSatelliteCommunication />,
      title: "Communications",
    },
    {
      tab: "8",
      icon: <GrLanguage />,
      title: "Language",
    },
  ]

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          {!isEmpty(settings) && (
            <>
              <Row>
                <AvForm
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(v)
                  }}
                >
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <CardTitle className="h4">Ecommerce Settings</CardTitle>
                        <p className="card-title-desc">
                          Create & update the data accordingly.
                        </p>

                        <Nav pills className="icon-tab nav-between ">
                          {map(settingsHeadItems, (headItem, key) => (
                            <NavItem key={key}>
                              <NavLink
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className={classnames({
                                  active: customIconActiveTab === headItem?.tab,
                                })}
                                onClick={() => {
                                  toggleIconCustom(headItem?.tab)
                                }}
                              >
                                {headItem?.icon}
                                <span className="d-none d-sm-block ms-2">
                                  {headItem?.title}
                                </span>
                              </NavLink>
                            </NavItem>
                          ))}
                        </Nav>

                        <TabContent
                          activeTab={customIconActiveTab}
                          className="mt-4 p-3 text-muted"
                        >
                          {/* Company */}
                          <TabPane tabId="1">
                            <Row className="mt-0">
                              <Col sm="12">
                                <CardText className="mb-0">
                                  <Row>
                                    {/* Basic Data */}
                                    <Col className="col-6 d-flex flex-wrap">
                                      <h5 className="col-12 text_deals_green">
                                        Basic Data
                                      </h5>
                                      <p className="mb-4">
                                        Fill the basic company details.
                                      </p>
                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          Company Name :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            name="name"
                                            type="text"
                                            value={
                                              settings?.company?.name || ""
                                            }
                                          />
                                        </Col>
                                      </div>
                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          Mobile Number :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            name="contactDetails.mobile"
                                            type="phone"
                                            value={
                                              settings?.company?.contactDetails
                                                ?.mobile || ""
                                            }
                                          />
                                        </Col>
                                      </div>

                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          Email :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            name="contactDetails.email"
                                            type="email"
                                            value={
                                              settings?.company?.contactDetails
                                                ?.email || ""
                                            }
                                          />
                                        </Col>
                                      </div>

                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          Website :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            name="contactDetails.website"
                                            type="text"
                                            value={
                                              settings?.company?.contactDetails
                                                ?.website || ""
                                            }
                                          />
                                        </Col>
                                      </div>

                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          Currency :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            name="defaultCurrency"
                                            type="text"
                                            value={
                                              settings?.company
                                                ?.defaultCurrency || ""
                                            }
                                          />
                                        </Col>
                                      </div>
                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          Currency Symbol :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            name="defaultCurrencySymbol"
                                            type="text"
                                            value={
                                              settings?.company
                                                ?.defaultCurrencySymbol || ""
                                            }
                                          />
                                        </Col>
                                      </div>

                                      <div className="form-group">
                                        <label>Logo :</label>

                                        <div className="custom-file">
                                          <input
                                            type="file"
                                            name="product_images"
                                            className="custom-file-input"
                                            id="customFile"
                                            onChange={onChangeImageHandler}
                                          />
                                          <label
                                            className="custom-file-label"
                                            htmlFor="customFile"
                                          >
                                            Choose Logo
                                          </label>
                                        </div>

                                        {oldImages &&
                                          oldImages.map(img => (
                                            <img
                                              key={img}
                                              src={img.url}
                                              alt={img.url}
                                              className="mt-3 mr-2"
                                              width="55"
                                              height="52"
                                            />
                                          ))}

                                        {imagesPreview.map(img => (
                                          <img
                                            src={img}
                                            key={img}
                                            alt="Images Preview"
                                            className="mt-3 mr-2"
                                            width="55"
                                            height="52"
                                          />
                                        ))}
                                      </div>
                                    </Col>
                                    {/* Address */}
                                    <Col className="col-6 d-flex flex-wrap">
                                      <h5 className="col-12 text_deals_green">
                                        Address
                                      </h5>
                                      <p className="mb-4">
                                        Fill the company address.
                                      </p>
                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          Address Line 1 :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            name="address.addressLine1"
                                            type="text"
                                            value={
                                              settings?.company?.address
                                                ?.addressLine1 || ""
                                            }
                                          />
                                        </Col>
                                      </div>

                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          Address Line 2 :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            name="address.addressLine2"
                                            type="text"
                                            value={
                                              settings?.company?.address
                                                ?.addressLine2 || ""
                                            }
                                          />
                                        </Col>
                                      </div>

                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          District :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            name="address.district"
                                            type="text"
                                            value={
                                              settings?.company?.address
                                                ?.district || ""
                                            }
                                          />
                                        </Col>
                                      </div>

                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          State :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            name="address.state"
                                            type="text"
                                            value={
                                              settings?.company?.address
                                                ?.state || ""
                                            }
                                          />
                                        </Col>
                                      </div>

                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          Country :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            name="address.country"
                                            type="text"
                                            value={
                                              settings?.company?.address
                                                ?.country || ""
                                            }
                                          />
                                        </Col>
                                      </div>

                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          Pin Code :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            name="address.pinCode"
                                            type="number"
                                            value={
                                              settings?.company?.address
                                                ?.pinCode || ""
                                            }
                                          />
                                        </Col>
                                      </div>
                                    </Col>
                                    {/* Social Links */}
                                    <hr className="mt-4" />
                                    <h5 className="mt-3 text_deals_green">
                                      Social Media
                                    </h5>
                                    <p>
                                      Enter the links to social media profiles
                                      (url)
                                    </p>
                                    <Col className="col-12 mt-3 d-flex flex-wrap">
                                      <div className="col-3 mb-3 mx-3">
                                        <AvField
                                          name="socialLinks.facebook"
                                          label="Facebook"
                                          type="text"
                                          value={
                                            settings?.company?.socialLinks
                                              ?.facebook || ""
                                          }
                                        />
                                      </div>
                                      <div className="col-3 mb-3 mx-3">
                                        <AvField
                                          name="socialLinks.instagram"
                                          label="Instagram"
                                          type="text"
                                          value={
                                            settings?.company?.socialLinks
                                              ?.instagram || ""
                                          }
                                        />
                                      </div>
                                      <div className="col-3 mb-3 mx-3">
                                        <AvField
                                          name="socialLinks.linkedin"
                                          label="Linkedin"
                                          type="text"
                                          value={
                                            settings?.company?.socialLinks
                                              ?.linkedin || ""
                                          }
                                        />
                                      </div>

                                      <div className="col-3 mb-3 mx-3">
                                        <AvField
                                          name="socialLinks.whatsApp"
                                          label="WhatsApp"
                                          type="text"
                                          value={
                                            settings?.company?.socialLinks
                                              ?.whatsApp || ""
                                          }
                                        />
                                      </div>
                                      <div className="col-3 mb-3 mx-3">
                                        <AvField
                                          name="socialLinks.snapchat"
                                          label="Snapchat"
                                          type="text"
                                          value={
                                            settings?.company?.socialLinks
                                              ?.snapchat || ""
                                          }
                                        />
                                      </div>
                                      <div className="col-3 mb-3 mx-3">
                                        <AvField
                                          name="socialLinks.tiktok"
                                          label="Tiktok"
                                          type="text"
                                          value={
                                            settings?.company?.socialLinks
                                              ?.tiktok || ""
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </CardText>
                              </Col>
                            </Row>
                          </TabPane>

                          {/* Tax Details */}
                          <TabPane tabId="2">
                            <Row className="mt-0">
                              <Col sm="12">
                                <CardText className="mb-0">
                                  <Row>
                                    <Col className="col-6 ">
                                      {/* <h5 className="mb-4" style={{color: '#1e81b0'}}>Basic Data</h5> */}
                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          Tax ID :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            name="taxId"
                                            type="text"
                                            value={
                                              settings?.taxDetails?.taxId || ""
                                            }
                                          />
                                        </Col>
                                      </div>
                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          Tax Phrase :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            name="taxPhrase"
                                            type="text"
                                            placeholder="eg: GST / VAT"
                                            value={
                                              settings?.taxDetails?.taxPhrase ||
                                              ""
                                            }
                                          />
                                        </Col>
                                      </div>

                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          Default Tax % :
                                        </Label>
                                        <Col sm={4}>
                                          <AvField
                                            name="defaultTaxPercentage"
                                            type="number"
                                            value={
                                              settings?.taxDetails
                                                ?.defaultTaxPercentage || ""
                                            }
                                          />
                                        </Col>
                                      </div>
                                    </Col>

                                    <Col className="col-6 ">
                                      <div className="col-6 mb-3 ">
                                        <AvGroup check>
                                          <AvInput
                                            type="checkbox"
                                            name="isMultipleTax"
                                            id="checkbox6"
                                            value={
                                              settings?.taxDetails
                                                ?.isMultipleTax || false
                                            }
                                          />
                                          <Label check for="checkbox6">
                                            {" "}
                                            Is Multiple Tax
                                          </Label>
                                        </AvGroup>
                                      </div>

                                      {/* Tax Categories */}
                                      <div className="col-12 mb-4">
                                        <Row>
                                          <Col md="3">
                                            <Label
                                              htmlFor="lbltype"
                                              className="form-label"
                                            >
                                              Tax Categories :{" "}
                                            </Label>
                                          </Col>
                                          <Col md="2">
                                            <button
                                              className="mx-2 btn"
                                              onClick={e => {
                                                e.preventDefault()
                                                setTaxCategories(
                                                  currentTaxCategory => [
                                                    ...currentTaxCategory,
                                                    {
                                                      name: "",
                                                      percentage: "",
                                                    },
                                                  ]
                                                )
                                              }}
                                            >
                                              <i
                                                className="bx bx-plus"
                                                style={{ fontSize: "20px" }}
                                              ></i>
                                            </button>
                                          </Col>
                                        </Row>

                                        <Col md="12" className="mx-3">
                                          {taxCategories?.map((s, index) => {
                                            return (
                                              <div key={index}>
                                                <Row>
                                                  <Col>
                                                    <AvField
                                                      name="newName"
                                                      type="text"
                                                      value={s.name}
                                                      placeholder="Name"
                                                      onChange={e => {
                                                        const name =
                                                          e.target.value
                                                        setTaxCategories(
                                                          currentTaxCategory =>
                                                            currentTaxCategory.map(
                                                              x =>
                                                                x === s
                                                                  ? {
                                                                      ...x,
                                                                      name,
                                                                    }
                                                                  : x
                                                            )
                                                        )
                                                      }}
                                                    />
                                                  </Col>

                                                  <Col className="db__column">
                                                    <AvField
                                                      name="percentage"
                                                      type="text"
                                                      value={s.percentage}
                                                      placeholder="Percentage"
                                                      onChange={e => {
                                                        const percentage =
                                                          e.target.value
                                                        setTaxCategories(
                                                          currentTaxCategory =>
                                                            currentTaxCategory.map(
                                                              x =>
                                                                x === s
                                                                  ? {
                                                                      ...x,
                                                                      percentage,
                                                                    }
                                                                  : x
                                                            )
                                                        )
                                                      }}
                                                    />
                                                  </Col>

                                                  <Col>
                                                    <button
                                                      className="mx-2 btn"
                                                      onClick={e => {
                                                        e.preventDefault()
                                                        setTaxCategories(
                                                          currentTaxCategory =>
                                                            currentTaxCategory.filter(
                                                              x => x !== s
                                                            )
                                                        )
                                                      }}
                                                    >
                                                      <i
                                                        className="bx bx-trash"
                                                        style={{
                                                          fontSize: "20px",
                                                        }}
                                                      ></i>
                                                    </button>
                                                  </Col>
                                                </Row>
                                              </div>
                                            )
                                          })}
                                        </Col>
                                      </div>
                                    </Col>
                                  </Row>
                                </CardText>
                              </Col>
                            </Row>
                          </TabPane>

                          {/* Payment */}
                          <TabPane tabId="3">
                            <Row className="mt-0">
                              <Col sm="12">
                                <CardText className="mb-0">
                                  <Row>
                                    <Col className="col-6 ">
                                      {/* <h5 className="mb-4" style={{color: '#1e81b0'}}>Basic Data</h5> */}
                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          Payment Type :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            type="select"
                                            name="paymentType"
                                            value={
                                              settings?.payment?.paymentType ||
                                              ""
                                            }
                                            helpMessage="Select a payment type"
                                          >
                                            {paymentTypes?.map((item, key) => (
                                              <option key={key} value={item}>
                                                {item}
                                              </option>
                                            ))}
                                          </AvField>
                                        </Col>
                                      </div>

                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          Payment Gateway :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            type="select"
                                            name="paymentGateway"
                                            value={
                                              settings?.payment
                                                ?.paymentGateway || ""
                                            }
                                            onChange={e =>
                                              setCreditionalsChange(
                                                e.target.value
                                              )
                                            }
                                            helpMessage="Select a payment Gateway"
                                          >
                                            {paymentGateways?.map(
                                              (item, key) => (
                                                <option key={key} value={item}>
                                                  {item}
                                                </option>
                                              )
                                            )}
                                          </AvField>
                                        </Col>
                                      </div>
                                    </Col>
                                    <Col className="col-12 ">
                                      {/* <p>Creditionals :</p> */}
                                      <h5 className="mb-4 text_deals_green">
                                        Creditionals
                                      </h5>

                                      {creditionalsChange === "RazorPay" && (
                                        <>
                                          <div className=" col-12 row mb-4">
                                            <Label className="col-sm-2 col-form-label text-end">
                                              {" "}
                                              RazorPay Key ID :
                                            </Label>
                                            <Col sm={10}>
                                              <AvField
                                                name="encryptedKeyId"
                                                type="text"
                                                value={
                                                  settings?.payment
                                                    ?.razorPayCreditionals
                                                    ?.encryptedKeyId || ""
                                                }
                                              />
                                            </Col>
                                          </div>

                                          <div className=" col-12 row mb-4">
                                            <Label className="col-sm-2 col-form-label text-end">
                                              {" "}
                                              RazorPay Key Secret :
                                            </Label>
                                            <Col sm={10}>
                                              <AvField
                                                name="encryptedKeySecret"
                                                type="text"
                                                value={
                                                  settings?.payment
                                                    ?.razorPayCreditionals
                                                    ?.encryptedKeySecret || ""
                                                }
                                              />
                                            </Col>
                                          </div>
                                        </>
                                      )}

                                      {creditionalsChange ===
                                        "Open Payments" && (
                                        <>
                                          <div className=" col-12 row mb-4">
                                            <Label className="col-sm-2 col-form-label text-end">
                                              {" "}
                                              Api Key :
                                            </Label>
                                            <Col sm={10}>
                                              <AvField
                                                name="encryptedApiKey"
                                                type="text"
                                                value={
                                                  settings?.payment
                                                    ?.openPaymentCreditionals
                                                    ?.encryptedApiKey
                                                }
                                              />
                                            </Col>
                                          </div>
                                          <div className=" col-12 row mb-4">
                                            <Label className="col-sm-2 col-form-label text-end">
                                              {" "}
                                              Api Secret :
                                            </Label>
                                            <Col sm={10}>
                                              <AvField
                                                name="encryptedApiSecret"
                                                type="text"
                                                value={
                                                  settings?.payment
                                                    ?.openPaymentCreditionals
                                                    ?.encryptedApiSecret
                                                }
                                              />
                                            </Col>
                                          </div>
                                        </>
                                      )}

                                      {creditionalsChange === "PhonePe" && (
                                        <>
                                          <div className=" col-12 row mb-4">
                                            <Label className="col-sm-2 col-form-label text-end">
                                              {" "}
                                              Merchant Id:
                                            </Label>
                                            <Col sm={10}>
                                              <AvField
                                                name="merchantId"
                                                type="text"
                                                value={
                                                  settings?.payment
                                                    ?.phonePeCreditionals
                                                    ?.merchantId
                                                }
                                              />
                                            </Col>
                                          </div>
                                          <div className=" col-12 row mb-4">
                                            <Label className="col-sm-2 col-form-label text-end">
                                              {" "}
                                              PhonePe Key :
                                            </Label>
                                            <Col sm={10}>
                                              <AvField
                                                name="phonePeKey"
                                                type="text"
                                                value={
                                                  settings?.payment
                                                    ?.phonePeCreditionals
                                                    ?.phonePeKey
                                                }
                                              />
                                            </Col>
                                          </div>
                                        </>
                                      )}
                                    </Col>
                                  </Row>
                                </CardText>
                              </Col>
                            </Row>
                          </TabPane>

                          {/* Shipping */}
                          <TabPane tabId="4">
                            <Row className="mt-0">
                              <Col sm="12">
                                <CardText className="mb-0">
                                  <Row>
                                    <Col className="col-6">
                                      {/* <h5 className="mb-4" style={{color: '#1e81b0'}}>Basic Data</h5> */}
                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-5 col-form-label"
                                        >
                                          {" "}
                                          Default Shipping Charge :
                                        </Label>
                                        <Col sm={6}>
                                          <AvField
                                            name="defaultShippingCharge"
                                            type="number"
                                            value={
                                              settings?.shipping
                                                ?.defaultShippingCharge || ""
                                            }
                                          />
                                        </Col>
                                      </div>

                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-5 col-form-label"
                                        >
                                          {" "}
                                          Return Duration Limit :
                                        </Label>
                                        <Col sm={6}>
                                          <AvField
                                            name="returnDuration"
                                            type="number"
                                            value={
                                              settings?.shipping
                                                ?.returnDuration || ""
                                            }
                                          />
                                        </Col>
                                      </div>

                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-5 col-form-label"
                                        >
                                          {" "}
                                          Free Shipping Threshold :
                                        </Label>
                                        <Col sm={6}>
                                          <AvField
                                            name="freeShippingThreshold"
                                            type="number"
                                            value={
                                              settings?.shipping
                                                ?.freeShippingThreshold || ""
                                            }
                                          />
                                        </Col>
                                      </div>

                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-5 col-form-label"
                                        >
                                          {" "}
                                          COD Charge:
                                        </Label>
                                        <Col sm={6}>
                                          <AvField
                                            name="codCharge"
                                            type="number"
                                            value={
                                              settings?.shipping?.codCharge ||
                                              ""
                                            }
                                          />
                                        </Col>
                                      </div>

                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-5 col-form-label"
                                        >
                                          {" "}
                                          Delivery Option :
                                        </Label>
                                        <Col sm={6}>
                                          <AvField
                                            type="select"
                                            name="deliveryOption"
                                            value={
                                              settings?.shipping
                                                ?.deliveryOption || ""
                                            }
                                            helpMessage="Select a delivery option"
                                          >
                                            <option>
                                              Select a delivery option
                                            </option>
                                            {deliveryOptions?.map(
                                              (item, key) => (
                                                <option key={key} value={item}>
                                                  {item}
                                                </option>
                                              )
                                            )}
                                          </AvField>
                                        </Col>
                                      </div>

                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-5 col-form-label"
                                        >
                                          {" "}
                                          Same-Day Time Limit :
                                        </Label>
                                        <Col sm={6}>
                                          <AvField
                                            name="sameDayTimeLimit"
                                            type="text"
                                            value={
                                              settings?.shipping
                                                ?.sameDayTimeLimit || ""
                                            }
                                          />
                                        </Col>
                                      </div>

                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-5 col-form-label"
                                        >
                                          {" "}
                                          Delivery Duration :
                                        </Label>
                                        <Col sm={6}>
                                          <AvField
                                            name="deliveryDuration"
                                            type="number"
                                            value={
                                              settings?.shipping
                                                ?.deliveryDuration || ""
                                            }
                                          />
                                        </Col>
                                      </div>

                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-5 col-form-label"
                                        >
                                          {" "}
                                          Tracking BaseUrl :
                                        </Label>
                                        <Col sm={6}>
                                          <AvField
                                            name="trackingBaseUrl"
                                            type="text"
                                            value={
                                              settings?.shipping
                                                ?.trackingBaseUrl || ""
                                            }
                                          />
                                        </Col>
                                      </div>

                                      <AvInput
                                        type="checkbox"
                                        name="isTrackingAvailable"
                                        value={
                                          settings?.shipping
                                            ?.isTrackingAvailable || false
                                        }
                                        id="checkbox4"
                                      />
                                      <Label
                                        check
                                        for="checkbox4"
                                        className="mx-2"
                                      >
                                        {" "}
                                        Is Tracking Available
                                      </Label>
                                    </Col>

                                    <Col className="col-6">
                                      <h6
                                        style={{
                                          textDecoration: "underline",
                                        }}
                                      >
                                        Logistics
                                      </h6>

                                      <div className=" col-12 mt-4 mb-4">
                                        <AvInput
                                          type="checkbox"
                                          name="isShiprocketConnected"
                                          value={
                                            settings?.logistics
                                              ?.isShiprocketConnected || false
                                          }
                                          id="isShiprocketConnected"
                                          onChange={e =>
                                            setIsShiprocketConnected(
                                              e.target.checked
                                            )
                                          }
                                        />
                                        <Label
                                          check
                                          htmlFor="isShiprocketConnected"
                                          className="mx-2"
                                        >
                                          Is Shiprocket Connected:
                                        </Label>
                                      </div>
                                      {isShiprocketConnected && (
                                        <>
                                          {" "}
                                          <div className=" col-12 row mb-4">
                                            <Label className="col-sm-5 col-form-label">
                                              {" "}
                                              Token :
                                            </Label>
                                            <Col sm={6}>
                                              <AvField
                                                name="shiprocketToken"
                                                type="text"
                                                value={
                                                  settings?.logistics
                                                    ?.shiprocketToken || ""
                                                }
                                              />
                                            </Col>
                                          </div>
                                          <div className=" col-12 row mb-4">
                                            <Label className="col-sm-5 col-form-label">
                                              {" "}
                                              BaseUrl :
                                            </Label>
                                            <Col sm={6}>
                                              <AvField
                                                name="shiprocketBaseUrl"
                                                type="text"
                                                value={
                                                  settings?.logistics
                                                    ?.shiprocketBaseUrl || ""
                                                }
                                              />
                                            </Col>
                                          </div>
                                          <div className=" col-12 row mb-4">
                                            <Label className="col-sm-5 col-form-label">
                                              {" "}
                                              ApiEmail :
                                            </Label>
                                            <Col sm={6}>
                                              <AvField
                                                name="shiprocketApiEmail"
                                                type="text"
                                                value={
                                                  settings?.logistics
                                                    ?.shiprocketApiEmail || ""
                                                }
                                              />
                                            </Col>
                                          </div>
                                          <div className=" col-12 row mb-4">
                                            <Label className="col-sm-5 col-form-label">
                                              {" "}
                                              ApiPassword :
                                            </Label>
                                            <Col sm={6}>
                                              <AvField
                                                name="shiprocketApiPassword"
                                                type="text"
                                                value={
                                                  settings?.logistics
                                                    ?.shiprocketApiPassword ||
                                                  ""
                                                }
                                              />
                                            </Col>
                                          </div>
                                          <hr />
                                          <div className=" col-12 row mb-4">
                                            <div className="col-12  mb-3">
                                              <Label
                                                htmlFor="lbltype"
                                                className="form-label mb-3"
                                              >
                                                Pickup Locations:
                                              </Label>
                                              <ReactTags
                                                tags={tags}
                                                placeholder="Type Location and enter"
                                                // suggestions={suggestions}
                                                delimiters={delimiters}
                                                handleDelete={handleDeleteTag}
                                                handleAddition={handleAddition}
                                                handleDrag={handleDrag}
                                                handleTagClick={handleTagClick}
                                                // inputFieldPosition="inline"
                                                autocomplete
                                                allowUnique
                                                inline
                                              />
                                            </div>
                                          </div>
                                          <hr />
                                          <h6
                                            style={{
                                              textDecoration: "underline",
                                            }}
                                          >
                                            Default Package Dimensions:
                                          </h6>
                                          <div className="col-6 mt-4 row mb-4">
                                            <Label className="col-sm-5 col-form-label">
                                              {" "}
                                              Length :
                                            </Label>
                                            <Col sm={6}>
                                              <AvField
                                                name="length"
                                                type="number"
                                                value={
                                                  settings?.logistics
                                                    ?.defaultPackageDimensions
                                                    ?.length || ""
                                                }
                                              />
                                            </Col>
                                          </div>
                                          <div className="col-6 mt-4 row mb-4">
                                            <Label className="col-sm-5 col-form-label">
                                              {" "}
                                              Breadth :
                                            </Label>
                                            <Col sm={6}>
                                              <AvField
                                                name="breadth"
                                                type="number"
                                                value={
                                                  settings?.logistics
                                                    ?.defaultPackageDimensions
                                                    ?.breadth || ""
                                                }
                                              />
                                            </Col>
                                          </div>
                                          <div className="col-6 mt-4 row mb-4">
                                            <Label className="col-sm-5 col-form-label">
                                              {" "}
                                              Height :
                                            </Label>
                                            <Col sm={6}>
                                              <AvField
                                                name="height"
                                                type="number"
                                                value={
                                                  settings?.logistics
                                                    ?.defaultPackageDimensions
                                                    ?.height || ""
                                                }
                                              />
                                            </Col>
                                          </div>
                                          <div className="col-6 mt-4 row mb-4">
                                            <Label className="col-sm-5 col-form-label">
                                              {" "}
                                              Weight :
                                            </Label>
                                            <Col sm={6}>
                                              <AvField
                                                name="weight"
                                                type="number"
                                                value={
                                                  settings?.logistics
                                                    ?.defaultPackageDimensions
                                                    ?.weight || ""
                                                }
                                              />
                                            </Col>
                                          </div>
                                        </>
                                      )}
                                    </Col>
                                  </Row>
                                </CardText>
                              </Col>
                            </Row>
                          </TabPane>

                          {/* Legal */}
                          <TabPane tabId="5">
                            <Row>
                              <Col sm="12">
                                <div className=" col-12 row mb-4">
                                  <Label
                                    htmlFor="horizontal-firstname-Input"
                                    className="col-sm-2 col-form-label"
                                  >
                                    {" "}
                                    Establishment Name :
                                  </Label>
                                  <Col sm={10}>
                                    <AvField
                                      name="establishmentName"
                                      type="text"
                                      value={
                                        settings?.legal?.establishmentName || ""
                                      }
                                    />
                                  </Col>
                                </div>
                              </Col>
                              <Col sm="12" className="mt-4">
                                <h5 className=" mb-4 text_deals_green">
                                  Terms & Conditions :
                                </h5>
                                {!loading &&
                                settings &&
                                settings?.legal &&
                                settings?.legal?.terms &&
                                settings?.legal?.terms != undefined ? (
                                  <FormEditors
                                    content={settings?.legal?.terms}
                                    setContent={setTerms}
                                  />
                                ) : (
                                  <></>
                                )}
                              </Col>

                              <Col sm="12" className="mt-4">
                                <h5 className="mb-4 text_deals_green">
                                  Privacy Policy :
                                </h5>
                                {!loading &&
                                settings &&
                                settings?.legal &&
                                settings?.legal?.privacyPolicy &&
                                settings?.legal?.privacyPolicy != undefined ? (
                                  <FormEditors
                                    content={settings?.legal?.privacyPolicy}
                                    setContent={setPrivacyPolicy}
                                  />
                                ) : (
                                  <></>
                                )}
                              </Col>

                              <Col sm="12" className="mt-4">
                                <h5 className="mb-4 text_deals_green">
                                  Return Policy :
                                </h5>
                                {!loading &&
                                settings &&
                                settings?.legal &&
                                settings?.legal?.returnPolicy &&
                                settings?.legal?.returnPolicy != undefined ? (
                                  <FormEditors
                                    content={settings?.legal?.returnPolicy}
                                    setContent={setReturnPolicy}
                                  />
                                ) : (
                                  <></>
                                )}
                              </Col>
                            </Row>
                          </TabPane>

                          {/* Messages */}
                          <TabPane tabId="6">
                            <Row className="mt-0">
                              <Col sm="12">
                                <CardText className="mb-0">
                                  <Row>
                                    <Col className="col-6 ">
                                      <h5 className="mb-4 text_deals_green">
                                        Primary Language
                                      </h5>
                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          Banner Offer :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            name="specialMessages.primaryLang.bannerOffer"
                                            type="textarea"
                                            value={
                                              settings?.specialMessages
                                                ?.primaryLang?.bannerOffer || ""
                                            }
                                          />
                                        </Col>
                                      </div>

                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          Product Offer :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            name="specialMessages.primaryLang.productOffer"
                                            type="textarea"
                                            value={
                                              settings?.specialMessages
                                                ?.primaryLang?.productOffer ||
                                              ""
                                            }
                                          />
                                        </Col>
                                      </div>

                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          Shipping Offer :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            name="specialMessages.primaryLang.shippingOffer"
                                            type="textarea"
                                            value={
                                              settings?.specialMessages
                                                ?.primaryLang?.shippingOffer ||
                                              ""
                                            }
                                          />
                                        </Col>
                                      </div>

                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          Delivery Offer :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            name="specialMessages.primaryLang.deliveryOffer"
                                            type="textarea"
                                            value={
                                              settings?.specialMessages
                                                ?.primaryLang?.deliveryOffer ||
                                              ""
                                            }
                                          />
                                        </Col>
                                      </div>
                                    </Col>
                                    <Col className="col-6 ">
                                      <h5 className="mb-4 text_deals_green">
                                        Secondary Language
                                      </h5>
                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          Banner Offer :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            style={{ textAlign: "right" }}
                                            name="specialMessages.secondaryLang.bannerOffer"
                                            type="textarea"
                                            value={
                                              settings?.specialMessages
                                                ?.secondaryLang?.bannerOffer ||
                                              ""
                                            }
                                          />
                                        </Col>
                                      </div>

                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          Product Offer :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            style={{ textAlign: "right" }}
                                            name="specialMessages.secondaryLang.productOffer"
                                            type="textarea"
                                            value={
                                              settings?.specialMessages
                                                ?.secondaryLang?.productOffer ||
                                              ""
                                            }
                                          />
                                        </Col>
                                      </div>

                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          Shipping Offer :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            style={{ textAlign: "right" }}
                                            name="specialMessages.secondaryLang.shippingOffer"
                                            type="textarea"
                                            value={
                                              settings?.specialMessages
                                                ?.secondaryLang
                                                ?.shippingOffer || ""
                                            }
                                          />
                                        </Col>
                                      </div>

                                      <div className=" col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          Delivery Offer :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            style={{ textAlign: "right" }}
                                            name="specialMessages.secondaryLang.deliveryOffer"
                                            type="textarea"
                                            value={
                                              settings?.specialMessages
                                                ?.secondaryLang
                                                ?.deliveryOffer || ""
                                            }
                                          />
                                        </Col>
                                      </div>
                                    </Col>
                                  </Row>
                                </CardText>
                              </Col>
                            </Row>
                          </TabPane>

                          {/* Setup */}
                          <TabPane tabId="7">
                            <Row className="mt-0">
                              <Col sm="12">
                                <CardText className="mb-0">
                                  <Row>
                                    <Col sm="12" lg={6} className="mb-3">
                                      <div className="col-12 row mb-4">
                                        <Label className="col-sm-4 col-form-label">
                                          {" "}
                                          Version :
                                        </Label>
                                        <Col sm={8}>
                                          <div
                                            className="btn-group mt-2 mt-xl-0 d-flex align-items-center justify-content-start"
                                            role="group"
                                            aria-label="Basic radio toggle button group"
                                          >
                                            {map(
                                              SiteVersionData,
                                              (item, key) => (
                                                <div key={key} className="me-3">
                                                  <input
                                                    type="radio"
                                                    className="btn-check"
                                                    name="btnradio"
                                                    id={item.value}
                                                    autoComplete="off"
                                                    checked={
                                                      siteVersion === item.value
                                                    }
                                                    value={item.value}
                                                    onChange={e =>
                                                      setSiteVersion(
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="btn btn-outline-info"
                                                    htmlFor={item.value}
                                                  >
                                                    {item.title}
                                                  </label>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </Col>
                                      </div>
                                    </Col>
                                    <Col sm="12" lg={6} className="mb-3">
                                      <div className="col-6 mb-3 ">
                                        <AvGroup check>
                                          <AvInput
                                            type="checkbox"
                                            name="isCustomHomepage"
                                            id="checkBoxHome"
                                            value={
                                              settings?.setup
                                                ?.isCustomHomepage || false
                                            }
                                          />
                                          <Label check for="checkBoxHome">
                                            {" "}
                                            Is Custom Home Page
                                          </Label>
                                        </AvGroup>
                                      </div>
                                    </Col>
                                    <Col sm="12" lg={6} className="mb-3">
                                      <div className="col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          Order ID Tag :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            name="orderIdTag"
                                            type="text"
                                            value={
                                              settings?.setup?.orderIdTag || ""
                                            }
                                          />
                                        </Col>
                                      </div>
                                    </Col>
                                    <Col sm="12" lg={6} className="mb-3">
                                      <div className="col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          Default Store :
                                        </Label>
                                        <Col sm={8}>
                                          <FormGroup className="w-100 custom_store_select">
                                            <div className="mb-3 ajax-select mt-lg-0 select2-container">
                                              <Select
                                                onInputChange={handleEnters}
                                                value={selectedStore}
                                                placeholder={selectedStore}
                                                onChange={
                                                  handlerProductFinalValue
                                                }
                                                options={storeOptions}
                                                classNamePrefix="select-selection"
                                                isLoading={StoreLoading}
                                              />
                                            </div>
                                          </FormGroup>
                                        </Col>
                                      </div>
                                    </Col>

                                    <Col sm="12" lg={6} className="mb-3">
                                      <div className="col-12 row mb-4">
                                        <Label className="col-sm-4 col-form-label">
                                          {" "}
                                          Admin Email:
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            name="adminEmail"
                                            type="text"
                                            value={
                                              settings?.setup?.adminEmail || ""
                                            }
                                          />
                                        </Col>
                                      </div>
                                    </Col>
                                    <Col sm="12" lg={6} className="mb-3">
                                      <div className="col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-6 col-form-label"
                                        >
                                          {" "}
                                          Store Search Distance :
                                        </Label>
                                        <Col sm={6}>
                                          <AvField
                                            name="storeSearchDistance"
                                            type="text"
                                            value={
                                              settings?.setup
                                                ?.storeSearchDistance || ""
                                            }
                                          />
                                        </Col>
                                      </div>
                                    </Col>
                                    <Col sm="12" lg={6} className="mb-3">
                                      <div className="col-12 row mb-4">
                                        <Label className="col-sm-4 col-form-label">
                                          {" "}
                                          Front-end Url :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            name="frontendUrl"
                                            type="text"
                                            value={
                                              settings?.setup?.frontendUrl || ""
                                            }
                                          />
                                        </Col>
                                      </div>
                                    </Col>

                                    <Col sm="12"></Col>
                                    <Col className="col-6 ">
                                      <h5 className="mb-4 text_deals_green">
                                        Shipping Inputs
                                      </h5>

                                      {/* Shipping Inputs */}
                                      <div className="col-12 mb-4">
                                        <Row>
                                          <Col md="4" className="m-2">
                                            <button
                                              className="mx-2 btn btn-success"
                                              onClick={e => {
                                                e.preventDefault()
                                                setShippingInputs(
                                                  currentShippingInput => [
                                                    ...currentShippingInput,
                                                    {
                                                      name: "",
                                                      required: "",
                                                    },
                                                  ]
                                                )
                                              }}
                                            >
                                              ADD{" "}
                                              <i
                                                className="bx bx-plus"
                                                style={{ fontSize: "20px" }}
                                              ></i>
                                            </button>
                                          </Col>
                                        </Row>

                                        <Col md="12" className="mx-3">
                                          {shippingInputs?.map((s, index) => {
                                            return (
                                              <div key={index}>
                                                <Row>
                                                  <Col>
                                                    <AvField
                                                      type="select"
                                                      name="a"
                                                      value={s.name}
                                                      onChange={e => {
                                                        const name =
                                                          e.target.value
                                                        setShippingInputs(
                                                          currentShippingInput =>
                                                            currentShippingInput.map(
                                                              x =>
                                                                x === s
                                                                  ? {
                                                                      ...x,
                                                                      name,
                                                                    }
                                                                  : x
                                                            )
                                                        )
                                                      }}

                                                      // helpMessage="Select a Shipping Input"
                                                    >
                                                      <option>
                                                        Select a Shipping Input
                                                      </option>
                                                      {shippingInputData?.map(
                                                        (item, key) => (
                                                          <option
                                                            key={key}
                                                            value={item}
                                                          >
                                                            {item}
                                                          </option>
                                                        )
                                                      )}
                                                    </AvField>
                                                  </Col>

                                                  <Col>
                                                    <AvField
                                                      type="select"
                                                      name="b"
                                                      value={s.required}
                                                      onChange={e => {
                                                        const required =
                                                          e.target.value
                                                        setShippingInputs(
                                                          currentShippingInput =>
                                                            currentShippingInput.map(
                                                              x =>
                                                                x === s
                                                                  ? {
                                                                      ...x,
                                                                      required,
                                                                    }
                                                                  : x
                                                            )
                                                        )
                                                      }}
                                                    >
                                                      <option>
                                                        Choose importance
                                                      </option>
                                                      {requiredOrNot?.map(
                                                        (item, key) => (
                                                          <option
                                                            key={key}
                                                            value={item}
                                                          >
                                                            {item}
                                                          </option>
                                                        )
                                                      )}
                                                    </AvField>
                                                  </Col>

                                                  <Col>
                                                    <button
                                                      className="mx-2 btn"
                                                      onClick={e => {
                                                        e.preventDefault()
                                                        setShippingInputs(
                                                          currentShippingInput =>
                                                            currentShippingInput.filter(
                                                              x => x !== s
                                                            )
                                                        )
                                                      }}
                                                    >
                                                      <i
                                                        className="bx bx-trash"
                                                        style={{
                                                          fontSize: "20px",
                                                        }}
                                                      ></i>
                                                    </button>
                                                  </Col>
                                                </Row>
                                              </div>
                                            )
                                          })}
                                        </Col>
                                      </div>
                                    </Col>

                                    <Col className="col-6 ">
                                      <h5 className="mb-4 text_deals_green">
                                        Product Inputs
                                      </h5>

                                      {/* Product Inputs */}
                                      <div className="col-12 mb-4">
                                        <Row>
                                          <Col md="4" className="m-2">
                                            <button
                                              className="mx-2 btn btn-success"
                                              onClick={e => {
                                                e.preventDefault()
                                                setProductInputs(
                                                  currentProductInput => [
                                                    ...currentProductInput,
                                                    {
                                                      name: "",
                                                      required: "",
                                                    },
                                                  ]
                                                )
                                              }}
                                            >
                                              ADD{" "}
                                              <i
                                                className="bx bx-plus"
                                                style={{ fontSize: "20px" }}
                                              ></i>
                                            </button>
                                          </Col>
                                        </Row>

                                        <Col md="12" className="mx-3">
                                          {productInputs?.map((s, index) => {
                                            return (
                                              <div key={index}>
                                                <Row>
                                                  <Col>
                                                    <AvField
                                                      type="select"
                                                      name="a"
                                                      value={s.name}
                                                      onChange={e => {
                                                        const name =
                                                          e.target.value
                                                        setProductInputs(
                                                          currentProductInput =>
                                                            currentProductInput.map(
                                                              x =>
                                                                x === s
                                                                  ? {
                                                                      ...x,
                                                                      name,
                                                                    }
                                                                  : x
                                                            )
                                                        )
                                                      }}

                                                      // helpMessage="Select a Product Input"
                                                    >
                                                      <option>
                                                        Select a Product Input
                                                      </option>
                                                      {productInputData?.map(
                                                        (item, key) => (
                                                          <option
                                                            key={key}
                                                            value={item}
                                                          >
                                                            {item}
                                                          </option>
                                                        )
                                                      )}
                                                    </AvField>
                                                  </Col>

                                                  <Col>
                                                    <AvField
                                                      type="select"
                                                      name="b"
                                                      value={s.required}
                                                      onChange={e => {
                                                        const required =
                                                          e.target.value
                                                        setProductInputs(
                                                          currentProductInput =>
                                                            currentProductInput.map(
                                                              x =>
                                                                x === s
                                                                  ? {
                                                                      ...x,
                                                                      required,
                                                                    }
                                                                  : x
                                                            )
                                                        )
                                                      }}
                                                    >
                                                      <option>
                                                        Choose importance
                                                      </option>
                                                      {requiredOrNot?.map(
                                                        (item, key) => (
                                                          <option
                                                            key={key}
                                                            value={item}
                                                          >
                                                            {item}
                                                          </option>
                                                        )
                                                      )}
                                                    </AvField>
                                                  </Col>

                                                  <Col>
                                                    <button
                                                      className="mx-2 btn"
                                                      onClick={e => {
                                                        e.preventDefault()
                                                        setProductInputs(
                                                          currentProductInput =>
                                                            currentProductInput.filter(
                                                              x => x !== s
                                                            )
                                                        )
                                                      }}
                                                    >
                                                      <i
                                                        className="bx bx-trash"
                                                        style={{
                                                          fontSize: "20px",
                                                        }}
                                                      ></i>
                                                    </button>
                                                  </Col>
                                                </Row>
                                              </div>
                                            )
                                          })}
                                        </Col>
                                      </div>
                                    </Col>

                                    <div>
                                      <h5 className="mb-4 text_deals_green">
                                        Theme
                                      </h5>
                                      <div className="row">
                                        <Col md={3}>
                                          <p>Primary Color</p>
                                          <ChromePicker
                                            width="180px"
                                            className="me-3"
                                            onChange={color =>
                                              setPrimaryColor(color?.hex)
                                            }
                                            color={primaryColor}
                                          />{" "}
                                        </Col>
                                        <Col md={3}>
                                          <p>Secondary Color</p>

                                          <ChromePicker
                                            width="180px"
                                            className="me-3"
                                            onChange={color =>
                                              setSecondaryColor(color?.hex)
                                            }
                                            color={secondaryColor}
                                          />
                                        </Col>
                                      </div>
                                    </div>

                                    <hr className="mt-3 " />

                                    {/* <Col className="col-6 mt-3">
                                      <h5
                                        className="mb-4 text_deals_green"
                                      >
                                        Classification Inputs
                                      </h5>

                                      <div className="col-12 mb-4">
                                        <Row>
                                          <Col md="4" className="m-2">
                                            <button
                                              className="mx-2 btn btn-success"
                                              onClick={e => {
                                                e.preventDefault()
                                                setClassificationInputs(
                                                  currentClassificationInput => [
                                                    ...currentClassificationInput,
                                                    {
                                                      name: "",
                                                      required: "",
                                                    },
                                                  ]
                                                )
                                              }}
                                            >
                                              ADD{" "}
                                              <i
                                                className="bx bx-plus"
                                                style={{ fontSize: "20px" }}
                                              ></i>
                                            </button>
                                          </Col>
                                        </Row>

                                        <Col md="12" className="mx-3">
                                          {classificationInputs?.map(
                                            (s, index) => {
                                              return (
                                                <div key={index}>
                                                  <Row>
                                                    <Col>
                                                      <AvField
                                                        type="select"
                                                        name="a"
                                                        value={s.name}
                                                        onChange={e => {
                                                          const name =
                                                            e.target.value
                                                          setClassificationInputs(
                                                            currentClassificationInput =>
                                                              currentClassificationInput.map(
                                                                x =>
                                                                  x === s
                                                                    ? {
                                                                        ...x,
                                                                        name,
                                                                      }
                                                                    : x
                                                              )
                                                          )
                                                        }}

                                                      >
                                                        <option>
                                                          Select a
                                                          Classification Input
                                                        </option>
                                                        {classificationsInputData?.map(
                                                          (item, key) => (
                                                            <option
                                                              key={key}
                                                              value={item}
                                                            >
                                                              {item}
                                                            </option>
                                                          )
                                                        )}
                                                      </AvField>
                                                    </Col>

                                                    <Col>
                                                      <AvField
                                                        type="select"
                                                        name="b"
                                                        value={s.required}
                                                        onChange={e => {
                                                          const required =
                                                            e.target.value
                                                          setClassificationInputs(
                                                            currentClassificationInput =>
                                                              currentClassificationInput.map(
                                                                x =>
                                                                  x === s
                                                                    ? {
                                                                        ...x,
                                                                        required,
                                                                      }
                                                                    : x
                                                              )
                                                          )
                                                        }}
                                                      >
                                                        <option>
                                                          Choose importance
                                                        </option>
                                                        {requiredOrNot?.map(
                                                          (item, key) => (
                                                            <option
                                                              key={key}
                                                              value={item}
                                                            >
                                                              {item}
                                                            </option>
                                                          )
                                                        )}
                                                      </AvField>
                                                    </Col>

                                                    <Col>
                                                      <button
                                                        className="mx-2 btn"
                                                        onClick={e => {
                                                          e.preventDefault()
                                                          setClassificationInputs(
                                                            currentClassificationInput =>
                                                              currentClassificationInput.filter(
                                                                x => x !== s
                                                              )
                                                          )
                                                        }}
                                                      >
                                                        <i
                                                          className="bx bx-trash"
                                                          style={{
                                                            fontSize: "20px",
                                                          }}
                                                        ></i>
                                                      </button>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              )
                                            }
                                          )}
                                        </Col>
                                      </div>
                                    </Col>

                                    <Col className="col-6 mt-3">
                                      <h5
                                        className="mb-4 text_deals_green"
                                      >
                                        Classification Inputs
                                      </h5>

                                      <div className="col-12 mb-4">
                                        <Row>
                                          <Col md="4" className="m-2">
                                            <button
                                              className="mx-2 btn btn-success"
                                              onClick={e => {
                                                e.preventDefault()
                                                setClassificationInputs(
                                                  currentClassificationInput => [
                                                    ...currentClassificationInput,
                                                    {
                                                      name: "",
                                                      required: "",
                                                    },
                                                  ]
                                                )
                                              }}
                                            >
                                              ADD{" "}
                                              <i
                                                className="bx bx-plus"
                                                style={{ fontSize: "20px" }}
                                              ></i>
                                            </button>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col> */}
                                  </Row>
                                </CardText>
                              </Col>
                            </Row>
                          </TabPane>

                          {/* Language */}
                          <TabPane tabId="8">
                            <Row className="mt-0">
                              <Col sm="12">
                                <CardText className="mb-0">
                                  <Row>
                                    <Col className="col-6 ">
                                      <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-4 col-form-label"
                                      >
                                        {" "}
                                        Multi-Language :
                                      </Label>
                                      <Switch
                                        uncheckedIcon={<Offsymbol />}
                                        checkedIcon={<OnSymbol />}
                                        className="me-1 mb-sm-8 mb-2"
                                        onColor="#626ed4"
                                        onChange={() => {
                                          setMultilanguage(!multilanguage)
                                        }}
                                        checked={multilanguage}
                                      />
                                      <div className="col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          Priority:
                                        </Label>

                                        <Col sm={8}>
                                          <AvField
                                            type="select"
                                            name="priority"
                                            value={
                                              settings?.multiLanguange
                                                ?.priority || ""
                                            }
                                            helpMessage="Select a language"
                                          >
                                            {languages?.map((item, key) => (
                                              <option key={key} value={item}>
                                                {item}
                                              </option>
                                            ))}
                                          </AvField>
                                        </Col>
                                      </div>

                                      <div className="col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-4 col-form-label"
                                        >
                                          {" "}
                                          Secondary Language :
                                        </Label>
                                        <Col sm={8}>
                                          <AvField
                                            type="text"
                                            name="secondaryLanguageName"
                                            value={
                                              settings?.multiLanguange
                                                ?.secondaryLanguageName || ""
                                            }
                                            helpMessage="Select a language"
                                          ></AvField>
                                        </Col>
                                      </div>
                                    </Col>
                                  </Row>
                                </CardText>
                              </Col>
                            </Row>
                          </TabPane>

                          {/* Communication */}
                          <TabPane tabId="9">
                            <Row className="mt-0">
                              <Col sm="12">
                                <CardText className="mb-0">
                                  <Row>
                                    <Col className="col-6 ">
                                      <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-4 col-form-label"
                                      >
                                        {" "}
                                        Whatsapp ApiConnected:
                                      </Label>
                                      <Switch
                                        uncheckedIcon={<Offsymbol />}
                                        checkedIcon={<OnSymbol />}
                                        className="mx-2 mb-sm-8 mb-3"
                                        onColor="#626ed4"
                                        onChange={() => {
                                          setWhatsappApiConnected(
                                            !whatsappApiConnected
                                          )
                                        }}
                                        checked={whatsappApiConnected}
                                      />

                                      <div className="col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-3 col-form-label"
                                        >
                                          {" "}
                                          ApiToken :
                                        </Label>
                                        <Col sm={9}>
                                          <AvField
                                            type="text"
                                            name="whatsappApiToken"
                                            value={
                                              settings?.communications
                                                ?.whatsappApiToken || ""
                                            }
                                          ></AvField>
                                        </Col>
                                      </div>
                                      <div className="col-12 row mb-4">
                                        <Label
                                          htmlFor="horizontal-firstname-Input"
                                          className="col-sm-3 col-form-label"
                                        >
                                          ApiBaseUrl :
                                        </Label>
                                        <Col sm={9}>
                                          <AvField
                                            type="text"
                                            name="whatsappApiBaseUrl"
                                            value={
                                              settings?.communications
                                                ?.whatsappApiBaseUrl || ""
                                            }
                                          ></AvField>
                                        </Col>
                                      </div>
                                    </Col>
                                  </Row>
                                </CardText>
                              </Col>
                            </Row>
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="col-12">
                    <Card>
                      <CardBody>
                        <Row className="col-12">
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Save
                            </button>
                          </div>
                          {/* <Col className="col-12">
                        <Link
                          to={`/settings/${settings?._id}`} 
                          className="btn btn-success">Update</Link>
                      </Col> */}
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </AvForm>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

Settings.propTypes = {
  settings: PropTypes.object,
  match: PropTypes.any,
  getSettings: PropTypes.func,
}

export default Settings

export const SiteVersionData = [
  {
    id: 11,
    title: "BASIC",
    value: "basic",
  },
  {
    id: 22,
    title: "PREMIUM",
    value: "premium",
  },

  {
    id: 33,
    title: "ADVANCED",
    value: "advanced",
  },
]
