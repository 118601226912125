module.exports = {
  primaryColor: "#22b3be",
  secondaryColor: "ed475f",
  companyName: "Novita",
  shortName: "Novita",
  siteMetadata: {
    websiteTitle: "Novita",
    description: "Novita by Wamier",
    keywords: "",
    faviconUrl: "",
    logoUrl: "",
    secondaryLogo: "",
  },
  apiEndpointDev: "http://192.168.1.2:4000",
  apiEndpointProd: "https://api.novitastore.in/",
  frontEndUrl: "https://novitastore.in/",
  tokenPrefix: "novita_token",
}
