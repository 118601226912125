import { AvField, AvForm } from "availity-reactstrap-validation"
import MyPagination from "components/Common/MyPagination"
import { debounce, map, range } from "lodash"
import moment from "moment"
import React, { useEffect, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Badge, Button, Card, CardBody, Col, Row, Spinner } from "reactstrap"
import { getAllTransactions } from "store/actions"

function TransactionsTable() {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setsearchText] = useState("")

  const { allTransationsList, loading } = useSelector(state => ({
    allTransationsList: state?.Revenues?.allTransations,
    loading: state.Revenues?.loading,
  }))
  console.log(allTransationsList?.transactions)
  useEffect(() => {
    dispatch(getAllTransactions(page, searchText))
  }, [dispatch, page, searchText])

  const columns = [
    {
      dataField: "date",
      text: "Date",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "order",
      text: "Order Id",
      sort: true,
    },

    {
      dataField: "phonePeTransactionId",
      text: "Payment - PhonePe",
      sort: true,
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
  ]

  const debounceOrderSearch = debounce(value => setsearchText(value), 600)
  const hanldeBadgeColor = status => {
    switch (status) {
      case "PENDING":
        return "warning"
      case "FAILED":
        return "danger"
      case "INITIATED":
        return "info"
      case "SUCCESS":
        return "success"
      case "ERRORED":
        return "danger"
      default:
        return "secondary"
    }
  }

  const transactionsData = map(allTransationsList?.transactions, item => ({
    ...item,
    order: (
      <Link to={`/order/${item?.order?._id}`}>
        <p>{item?.order?.orderId}</p>
      </Link>
    ),
    name: (
      <Link to={`/users/${item?.user?._id}`}>
        <p>{item?.user?.name ? item?.user?.name : item?.user?.phone}</p>
      </Link>
    ),
    date: <p>{moment(item?.createdAt).format("DD/MM/YYYY - h:mm a")}</p>,
    phonePeTransactionId: <p>{item?.phonePeTransactionId}</p>,
    amount: <p>$ {item?.amount}</p>,
    status: (
      <Badge
        className={`px-2 py-2 badge-soft-${hanldeBadgeColor(item?.status)}`}
      >
        {item?.status}
      </Badge>
    ),
  }))

  const totalPages = Math.ceil(allTransationsList?.total / 10)
  const pages = range(1, totalPages + 1)

  return (
    <div>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={"id"}
                columns={columns}
                data={transactionsData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col sm="4">
                        <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            {/* <SearchBar {...toolkitProps.searchProps} /> */}
                            <AvForm>
                              <AvField
                                name="searchText"
                                placeholder="Search"
                                type="text"
                                onChange={e =>
                                  debounceOrderSearch(e.target.value)
                                }
                              />
                            </AvForm>
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {allTransationsList?.transactions ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap floting-action"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No Transactions !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default TransactionsTable
